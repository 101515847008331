/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

Amplify.configure(environment.awsConfig);

Hub.listen('auth', ({ payload: { event } }) => {
  if (event === 'signInWithRedirect_failure') {
    localStorage.setItem('customState_failure', 'true');
    localStorage.removeItem('recruitmentInformation');
  }
});

if (environment.sentry.enabled) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.sentry.environmentName,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
    tracesSampleRate: environment.sentry.tracesSampleRate,
    tracePropagationTargets: [/^https:\/\/thrivable\.sentry\.io\/api/],
    replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
