{
  "name": "web-client",
  "version": "2.7.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "dev": "ng serve --configuration=development",
    "start:dev": "ng serve --configuration=development",
    "start:test": "ng serve --configuration=test",
    "build:test": "ng build  --configuration=test && pnpm sentry:sourcemaps",
    "build:prod": "ng build --configuration=production && pnpm sentry:sourcemaps",
    "build:test-localize": "ng build --configuration=test --localize=true && pnpm sentry:sourcemaps",
    "build:prod-localize": "ng build --configuration=production --localize=true && pnpm sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "format": "prettier --write \"src/**/*.{html,js,ts,scss}\" --log-level silent",
    "lint:format": "ng lint && prettier --write \"src/**/*.{html,js,ts,scss}\"",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org thrivable --project my-thrivable-app ./dist && sentry-cli sourcemaps upload --org thrivable --project my-thrivable-app ./dist"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.13",
    "@angular/common": "18.2.13",
    "@angular/compiler": "18.2.13",
    "@angular/core": "18.2.13",
    "@angular/forms": "18.2.13",
    "@angular/platform-browser": "18.2.13",
    "@angular/platform-browser-dynamic": "18.2.13",
    "@angular/router": "18.2.13",
    "@fortawesome/fontawesome-pro": "6.7.1",
    "@ng-select/ng-select": "13.9.1",
    "@sentry/angular": "8.42.0",
    "@sentry/cli": "2.39.1",
    "@types/google-libphonenumber": "7.4.30",
    "aws-amplify": "6.10.2",
    "google-libphonenumber": "3.2.40",
    "ng-recaptcha": "13.2.1",
    "ngx-drag-drop": "18.0.2",
    "ngx-editor": "18.0.0",
    "ngx-spinner": "17.0.0",
    "rxjs": "7.8.1",
    "tslib": "2.8.1",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "18.2.12",
    "@angular-eslint/builder": "18.4.3",
    "@angular-eslint/eslint-plugin": "18.4.3",
    "@angular-eslint/eslint-plugin-template": "18.4.3",
    "@angular-eslint/schematics": "18.4.3",
    "@angular-eslint/template-parser": "18.4.3",
    "@angular/cli": "18.2.12",
    "@angular/compiler-cli": "18.2.13",
    "@angular/localize": "18.2.13",
    "@tailwindcss/typography": "0.5.15",
    "@types/jasmine": "5.1.5",
    "@typescript-eslint/eslint-plugin": "8.17.0",
    "@typescript-eslint/parser": "8.17.0",
    "autoprefixer": "10.4.20",
    "daisyui": "4.12.14",
    "eslint": "9.16.0",
    "eslint-config-prettier": "9.1.0",
    "jasmine-core": "5.5.0",
    "karma": "6.4.4",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.1",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "postcss": "8.4.49",
    "prettier": "3.4.2",
    "prettier-plugin-tailwindcss": "0.6.9",
    "tailwindcss": "3.4.16",
    "typescript": "5.5.4"
  },
  "packageManager": "pnpm@9.15.0+sha512.76e2379760a4328ec4415815bcd6628dee727af3779aaa4c914e3944156c4299921a89f976381ee107d41f12cfa4b66681ca9c718f0668fa0831ed4c6d8ba56c"
}
