import {
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  importProvidersFrom,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PermissionsService } from './auth.guard';
import { HttpInterceptorProviders } from './shared/interceptors/interceptor-provider';
import { AmplifyService } from './account/services/amplify.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminPermissionsService } from './admin/admin.guard';
import { MemberPermissionsService } from './member/member.guard';
import { ToastComponent } from './shared/components/toast/toast.component';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { CompanyPermissionsService } from './company/company.guard';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompanyPagesPermissionsService } from './company/company-pages.guard';
import { BackgroundImageComponent } from './shared/components/background-image/background-image.component';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BackgroundImageComponent,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-clip-rotate-pulse' }),
    ToastComponent,
  ],
  providers: [
    importProvidersFrom(NgSelectModule),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    HttpInterceptorProviders,
    PermissionsService,
    AmplifyService,
    AdminPermissionsService,
    MemberPermissionsService,
    CompanyPermissionsService,
    CompanyPagesPermissionsService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
